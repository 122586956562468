import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import api from "../../API/api";
import Cat from "./cat";
import AuthUser from '../../API/token';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Question3 = () => {

    const MySwal = withReactContent(Swal)

    const { user } = AuthUser();
    const navigate = useNavigate();

    const [ans3, setAns3] = useState();
    const [userid, setUser] = useState(user.id);
    const [question, setQuestion] = useState(3);
    const [j3, setJ3] = useState("");

    useEffect(() => {
        getAnswer();
        if (user.expire == 1) {
            console.log("expire path");
            navigate('/success');
        }
        var now = Date().slice(16, 24);
        if (user.end_time <= now) {
            console.log("expire path", user.end_time);
            navigate('/success');
        }

    }, []);

    const handlenext = () => {
        handleSubmit()
        navigate('/q3')
    }




    const handleFinal = () => {
        handleSubmit()
        salert()

        // expire(userid)

        // navigate('/success')
    }

    const salert = () => {
        Swal.fire({
            title: 'Are you sure want to submit?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Submitted!',
                    'success'
                )
                expire(userid)
                navigate('/success')
            }
        })
    }

    const expire = () => {
        api.expire(user)
            .then((res) => {
                console.log("expire", res);
            })
            .catch((err) => {
                console.log(err.response);
            })

    }


    const handleback = () => {
        handleSubmit()
        navigate(-1);
    }


    const handledit = () => {
        setAns3('');
        getAnswer();
    }
    const getAnswer = () => {
        api.getAnswer(userid, question)
            .then((res) => {
                console.log(res.data.data.ans3);
                setAns3(res.data.data.ans3)
                setJ3(res.data.data.ans3 + 3)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleSubmit = () => {
        api.ans(ans3, userid, question)
            .then((res) => {
                console.log(res);
                if (res.status === 200) {

                    toast.success('Successfully Added', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else {
                    toast.error('Saved', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handlequestion1 = (e) => {
        setAns3(e);
        console.log(e);
    }
    const preventCopyPaste = (e) => {
        e.preventDefault()
        console.log("working");

    }

    const editor = useRef(null);
    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 620,
        triggerChangeEvent: true,
        readonly: false,
        buttons: [
            'bold',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],

        events: {
            processPaste: function (event, html) {
                console.log("oo");
                setAns3('');
                return false;
            },
            afterPaste: function (event) {
                return false;
            },
        },
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,


    }



    const Jodit = () => {

        return useMemo(() => (

            <JoditEditor
                ref={editor}
                config={config}
                value={ans3}
                onChange={handlequestion1}

            />
        ), [j3])
    }

    const question3 = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <p>
                                <strong> <u> Assignment 3</u> </strong><br />
                                You are working on the social media account of a prominent minister of Assam. Write a social media copy from his POV, about a site inspection completed of a commercial project coming up in Assam. Once completed, the project is expected to generate mass employment for the youth of the State. Feel free to improvise on the features and benefits of the project and create a compelling copy that will resonate with the people of Assam at large, while also drawing the attention of investors to the project.

                            </p>
                        </div>
                        <br />

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-10">

                        {Jodit()}

                        <div className="row mb-4 pb-4">
                            <div className="col">
                                <button onClick={handleback} className="btn btn-warning m-2">Back</button>
                            </div>
                            <div className="col text-end">
                                <button className="btn btn-info m-2" onClick={handleSubmit}>Save Progress</button>
                                <button onClick={handleFinal} className="btn btn-success m-2">Final Submit</button>
                            </div>
                        </div>

                    </div>


                    <div className="col-md-2">
                        <Cat question={question} />
                    </div>

                </div>


            </>
        )

    }

    return (
        <>
            <ToastContainer />
            <div className='container'>

                {question3()}

            </div>

        </>
    )
}

export default Question3;
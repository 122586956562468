import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
import api from "../../API/api";

import Cat from "./cat";
import AuthUser from '../../API/token';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Question2 = () => {
    const { user } = AuthUser();
    const navigate = useNavigate();

    const handlenext = () => {
        handleSubmit()
        navigate('/q3')
    }
    const handleback = () => {
        handleSubmit()
        navigate(-1);
    }

    const [ans2, setAns2] = useState();
    const [userid, setUser] = useState(user.id);
    const [question, setQuestion] = useState(2);
    const [j2, setJ2] = useState("");

    useEffect(() => {
        getAnswer();
        if(user.expire == 1){
            console.log("expire path");
            navigate('/success');
        }
        var now = Date().slice(16,24);
        if(user.end_time <= now){
            console.log("expire path", user.end_time);
            navigate('/success');
        }

    }, []);

    const getAnswer = () => {
        api.getAnswer(userid, question)
            .then((res) => {
                console.log(res);
                setAns2(res.data.data.ans2)
                setJ2(res.data.data.ans2 + 2)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleSubmit = () => {
        api.ans(ans2, userid, question)
            .then((res) => {
                console.log(res);
                if (res.status === 200) {

                    toast.success('Saved', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else {
                    toast.error('Something went wrong !', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handlequestion1 = (e) => {
        setAns2(e);
        console.log(e);
    }
    const preventCopyPaste = (e) => {
        e.preventDefault()
        console.log("working");

    }

    const editor = useRef(null);
    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 620,
        triggerChangeEvent: true,
        readonly: false,
        buttons: [
            'bold',
            'brush',
            'paragraph', '|',
            'align', '|',
            'undo', 'redo', '|',
            'eraser',
            'dots'
        ],

        events: {
            processPaste: function (event, html) {
                console.log("oo");
                setAns2('');
                return false;
            },
            afterPaste: function (event) {
                return false;
            },
        },
        askBeforePasteFromWord: false,
        askBeforePasteHTML: false,


    }



    const Jodit = () => {
        return useMemo(() => (
            <JoditEditor
                ref={editor}
                config={config}
                value={ans2}
                onChange={handlequestion1}
                onPaste={preventCopyPaste}

            />
        ), [j2])
    }

    const question2 = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <p>
                                <strong> <u> Assignment 2</u> </strong><br />
                                The client is an educational institution catering to nursing, physiotherapy and paramedical courses. Among their courses, they want to put more emphasis on the Bachelor of Optometry course, which is lesser known in the field of allied health sciences. Prepare a compelling campaign tagline along with the content for a landing page featuring the specific course. The target is to capture interest among the target students, leading them to visit the landing page, where they can make further enquiry for the course. 
                            </p>
                        </div>
                        <br />

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-10">

                        {Jodit()}

                        <div className="row mb-4 pb-4">
                            <div className="row mb-4 pb-4">
                                <div className="col">
                                    <button onClick={handleback} className="btn btn-warning m-2">Back</button>
                                </div>
                                <div className="col text-end">
                                    <button className="btn btn-info m-2" onClick={handleSubmit}>Save Progress</button>
                                    <button onClick={handlenext} className="btn btn-success m-2">Next</button>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-md-2">
                        <Cat question={question} />
                    </div>

                </div>


            </>
        )

    }

    return (
        <>
            <div className='container'>
            <ToastContainer />

                {question2()}

            </div>

        </>
    )
}

export default Question2;
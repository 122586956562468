import React from 'react'
import { useNavigate } from 'react-router-dom';

const Cat = (props)=>{
    const navigate = useNavigate();

    const handlePage = (page)=>{
        navigate(page);
    }


    
    return (
        <>
        <div className='row border p-3'>
            <button className={`btn  ${props.question == 1? 'btn-success': 'btn-outline-info' } m-2`} onClick={()=>handlePage('/q1')}>Question 1</button>
            <button className={`btn  ${props.question == 2? 'btn-success': 'btn-outline-info' } m-2`} onClick={()=>handlePage('/q2')}>Question 2</button>
            <button className={`btn  ${props.question == 3? 'btn-success': 'btn-outline-info' } m-2`} onClick={()=>handlePage('/q3')}>Question 3</button>
        </div>
        
        <div className='row border p-1'>
            <p>
       <strong> Note: </strong> 
       ‘Copy’, ‘Cut’ and ‘Paste’ features are disabled for this test session.
       </p> 
       <p>
       * You can save your progress and resume your test in between the allotted 6 hours time. 
       </p><p>
        * Please note that after clicking on the ‘FINAL SUBMIT’ button, you won’t be able to submit or modify answers (or reattempt the test).
       </p>
        </div>


        </>
    )
}

export default Cat;